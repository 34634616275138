<template>
  <div class="app-root-container">
    <div class="page-title">连接的应用</div>
    <div class="app-tip-container">
      <div style="max-width: 300px;margin-left: 70px;">Ooh,您还没有将平台连接到任何应用</div>
      <img :src="icons.appPlaceholder" style="width:400px;height:400px;margin-top:150px;" />
    </div>
  </div>
</template>

<script>
import * as icon from "../../common/icon";
export default {
  data() {
    return {
      icons: icon,
    };
  },
};
</script>

<style scoped>
.app-root-container {
  flex: 1;
  display: flex;
  flex-direction: column;
  padding-top: 30px;
  padding-left: 15px;
  background-color: #f9f9f9;
}
.app-tip-container {
  margin-top: 50px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  width: 1020px;
  height: 250px;
  border-radius: 15px;
  background-color: #ffffff;
  font-size: 32px;
  font-weight: bold;
  color: #000000;
  overflow: hidden;
}
</style>